// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-examples-jsx": () => import("./../src/pages/contact/examples.jsx" /* webpackChunkName: "component---src-pages-contact-examples-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-contact-thanks-jsx": () => import("./../src/pages/contact/thanks.jsx" /* webpackChunkName: "component---src-pages-contact-thanks-jsx" */),
  "component---src-pages-feedback-jsx": () => import("./../src/pages/feedback.jsx" /* webpackChunkName: "component---src-pages-feedback-jsx" */),
  "component---src-pages-news-index-jsx": () => import("./../src/pages/news/index.jsx" /* webpackChunkName: "component---src-pages-news-index-jsx" */),
  "component---src-templates-about-page-jsx": () => import("./../src/templates/about-page.jsx" /* webpackChunkName: "component---src-templates-about-page-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-contacts-page-jsx": () => import("./../src/templates/contacts-page.jsx" /* webpackChunkName: "component---src-templates-contacts-page-jsx" */),
  "component---src-templates-index-page-jsx": () => import("./../src/templates/index-page.jsx" /* webpackChunkName: "component---src-templates-index-page-jsx" */),
  "component---src-templates-service-page-jsx": () => import("./../src/templates/service-page.jsx" /* webpackChunkName: "component---src-templates-service-page-jsx" */)
}

